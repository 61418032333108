
<template>
  <div><!-- Placeholder --></div>
</template>

<script setup lang="ts">
import { ClockIcon } from "@heroicons/vue/24/outline";
import { useIdle } from "@vueuse/core";
import { ref, watch } from "vue";
import moment from "moment";
import { useAuth } from "../../stores/auth.ts";

// --- IDLE TIMER --- //
/*

    <div
      :class="[
            'aspect-square w-14 gap-1 rounded-lg overflow-hidden flex flex-col items-center justify-center ',
            'text-sm bg-transparent text-slate-500 dark:text-slate-400 transition-all duration-150'
          ]"
    >

      <ClockIcon class="w-6 h-6" />
      <div class="text-center">Reload<br />in {{ remainingTimeFormatted }}</div>
    </div>




const maximumIdleTime = 60*60*1000;
const { idle, lastActive } = useIdle(maximumIdleTime);

const remainingTime = ref(0);
const remainingTimeFormatted = ref("");

const formatRemainingTime = () => {
  remainingTime.value = maximumIdleTime - (Date.now() - lastActive.value);

  const duration = moment.duration(remainingTime.value);
  remainingTimeFormatted.value = duration.minutes() + ":" + duration.seconds();
};

watch (idle, (value) => value ? logoff(): null)*/

// --- SLEEP CHECK --- //

const lastTime = ref((new Date()).getTime());

const checkSleep = () => {
  const currentTime = (new Date()).getTime();
  if (currentTime > (lastTime.value + 2000*2)) {
    console.warn("Woke up from sleep, logging off!");
    logoff();
  }
  lastTime.value = currentTime;
};

// --- LOGOFF --- //
const logoff = () => {
  console.warn("Logging off due to inactivity");

  const auth = useAuth();
  auth.removeToken();
};


// --- TIMER STARTEN --- //

setInterval(checkSleep, 2000);
//setInterval(formatRemainingTime, 1000);
</script>
